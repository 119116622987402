<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage {{ type }}</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ {{ capitalize(type) }}
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar" v-if="checkIsAccessible('blog', 'create')">
                  <v-btn @click="blogCategory()" x-large text> News categories </v-btn>
                  <v-btn
                    @click="createNew()"
                    class="mt-1 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    {{ type }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    outlined
                    v-model="search.title"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    outlined
                    dense
                    v-on:keyup.enter="getBlogs()"
                    v-model="search.category_id"
                    :items="categories"
                    item-text="title"
                    item-value="id"
                    label="Category"
                  >
                  </v-select>
                  <!--                                <select-->
                  <!--                                        class="form-control"-->
                  <!--                                        v-model="search.category_id"-->
                  <!--                                        name-->
                  <!--                                        id-->
                  <!--                                >-->
                  <!--                                    <option value="null">Filter by Category</option>-->
                  <!--                                    <option-->
                  <!--                                            :value="category.id"-->
                  <!--                                            v-for="(category, i) of categories"-->
                  <!--                                            :key="i"-->
                  <!--                                    >{{category.title}}-->
                  <!--                                    </option>-->
                  <!--                                </select>-->
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="search.publish_date_from"
                        label="From"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        v-on:keyup.enter="getBlogs()"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="search.publish_date_from"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="search.publish_date_to"
                        label="To"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        v-on:keyup.enter="getBlogs()"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="search.publish_date_to"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" :md="type === 'event' ? 3 : 4">
                  <v-select
                    :items="active_statuses"
                    item-text="name"
                    item-value="value"
                    outlined
                    v-on:keyup.enter="getBlogs()"
                    dense
                    v-model="search.active"
                  >
                  </v-select>
                </v-col>

                <v-col v-show="type === 'event'" cols="12" md="2">
                  <v-select
                    class="form-control"
                    outlined
                    dense
                    v-on:keyup.enter="getBlogs()"
                    v-model="search.filter_by"
                    :items="['Upcoming', 'Past']"
                    label="Filter By"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" :md="type === 'event' ? 3 : 4" class="text-right">
                  <v-btn
                    @click.prevent="getBlogs"
                    class="btn btn-primary w-35"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

              </div>
            </div>
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Date</th>
                    <th class="px-3 wrap-column" v-show="type === 'event'">Event date</th>
                    <th class="px-3 wrap-column">Category</th>
                    <th class="px-3 wrap-column">Action</th>
                  </tr>
                </thead>
                <template>
                  <tr
                    v-show="blogs.length > 0"
                    v-for="(blog, index) of blogs"
                    :key="index"
                  >
                    <td class="px-3 wrap-column">
                      <a href="#" @click.prevent="editblog(blog)" class="mr-2">
                        {{ blog.title }}</a
                      >&nbsp;&nbsp;
                      <i
                        class="fas fa-circle"
                        :class="blog.is_active ? 'dot-active' : 'dot-inactive'"
                      ></i>
                    </td>
                    <td class="px-3 wrap-column">
                      {{ blog.pub_date }}
                    </td>
                    <td class="px-3 wrap-column" v-show="type === 'event'">
                      {{ blog.event_date ? blog.event_date : 'NA' }}
                    </td>
                    <td class="px-3 wrap-column">
                      <div class v-if="checkLength(blog.category_titles)">
                        <span
                          class="badge badge-secondary m-1"
                          v-for="(title, i) of blog.category_titles"
                          :key="i"
                          >{{ title }}</span
                        >
                      </div>
                      <div class v-else>
                        <span class="badge badge-default">-</span>
                      </div>
                    </td>

                    <td class="px-3 wrap-column">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('blog', 'edit')"
                          >
                            <a href="#" class="navi-link" @click="editblog(blog)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('blog', 'delete')"
                          >
                            <a href="#" class="navi-link" @click="deleteblog(blog.id)">
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="blogs.length == 0">
                    <td colspan="5" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                v-if="blogs.length > 0"
                @input="getBlogs"
                class="pull-right mt-7"
                v-model="page"
                :total-rows="total"
                :disabled="loading"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
            <!--        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">



&lt;!&ndash;            <form @submit.prevent="getBlogs" class="row mt-5 mb-5 bg-filter">

              <div class="col-md-2 my-auto">
                <button class="btn btn-primary" @click="getBlogs">
                  Filter
                </button>
&lt;!&ndash;                <button class="btn btn-secondary ml-2" @click="_reset">&ndash;&gt;
&lt;!&ndash;                  Reset&ndash;&gt;
&lt;!&ndash;                </button>&ndash;&gt;
              </div>
            </form>&ndash;&gt;
            <div class="row">
&lt;!&ndash;              <div class="col-md-12" v-show="type === 'event'">&ndash;&gt;
&lt;!&ndash;                <v-sheet height="64">&ndash;&gt;
&lt;!&ndash;                  <v-toolbar flat>&ndash;&gt;
&lt;!&ndash;                    <v-btn&ndash;&gt;
&lt;!&ndash;                        outlined&ndash;&gt;
&lt;!&ndash;                        class="mr-4"&ndash;&gt;
&lt;!&ndash;                        color="grey darken-2"&ndash;&gt;
&lt;!&ndash;                        @click="setToday"&ndash;&gt;
&lt;!&ndash;                    >&ndash;&gt;
&lt;!&ndash;                      Today&ndash;&gt;
&lt;!&ndash;                    </v-btn>&ndash;&gt;
&lt;!&ndash;                    <v-btn fab text small color="grey darken-2" @click="prev">&ndash;&gt;
&lt;!&ndash;                      <v-icon small>&ndash;&gt;
&lt;!&ndash;                        mdi-chevron-left&ndash;&gt;
&lt;!&ndash;                      </v-icon>&ndash;&gt;
&lt;!&ndash;                    </v-btn>&ndash;&gt;
&lt;!&ndash;                    <v-btn fab text small color="grey darken-2" @click="next">&ndash;&gt;
&lt;!&ndash;                      <v-icon small>&ndash;&gt;
&lt;!&ndash;                        mdi-chevron-right&ndash;&gt;
&lt;!&ndash;                      </v-icon>&ndash;&gt;
&lt;!&ndash;                    </v-btn>&ndash;&gt;
&lt;!&ndash;                    <v-toolbar-title v-if="$refs.calendar">&ndash;&gt;
&lt;!&ndash;                      {{ $refs.calendar.title }}&ndash;&gt;
&lt;!&ndash;                    </v-toolbar-title>&ndash;&gt;
&lt;!&ndash;                    <v-spacer></v-spacer>&ndash;&gt;
&lt;!&ndash;                    <v-menu bottom right>&ndash;&gt;
&lt;!&ndash;                      <template v-slot:activator="{ on, attrs }">&ndash;&gt;
&lt;!&ndash;                        <v-btn&ndash;&gt;
&lt;!&ndash;                            outlined&ndash;&gt;
&lt;!&ndash;                            color="grey darken-2"&ndash;&gt;
&lt;!&ndash;                            v-bind="attrs"&ndash;&gt;
&lt;!&ndash;                            v-on="on"&ndash;&gt;
&lt;!&ndash;                        >&ndash;&gt;
&lt;!&ndash;                          <span>{{ typeToLabel[ctype] }}</span>&ndash;&gt;
&lt;!&ndash;                          <v-icon right>&ndash;&gt;
&lt;!&ndash;                            mdi-menu-down&ndash;&gt;
&lt;!&ndash;                          </v-icon>&ndash;&gt;
&lt;!&ndash;                        </v-btn>&ndash;&gt;
&lt;!&ndash;                      </template>&ndash;&gt;
&lt;!&ndash;                      <v-list>&ndash;&gt;
&lt;!&ndash;                        <v-list-item @click="ctype = 'day'">&ndash;&gt;
&lt;!&ndash;                          <v-list-item-title>Day</v-list-item-title>&ndash;&gt;
&lt;!&ndash;                        </v-list-item>&ndash;&gt;
&lt;!&ndash;                        <v-list-item @click="ctype = 'week'">&ndash;&gt;
&lt;!&ndash;                          <v-list-item-title>Week</v-list-item-title>&ndash;&gt;
&lt;!&ndash;                        </v-list-item>&ndash;&gt;
&lt;!&ndash;                        <v-list-item @click="ctype = 'month'">&ndash;&gt;
&lt;!&ndash;                          <v-list-item-title>Month</v-list-item-title>&ndash;&gt;
&lt;!&ndash;                        </v-list-item>&ndash;&gt;
&lt;!&ndash;                        <v-list-item @click="ctype = '4day'">&ndash;&gt;
&lt;!&ndash;                          <v-list-item-title>4 days</v-list-item-title>&ndash;&gt;
&lt;!&ndash;                        </v-list-item>&ndash;&gt;
&lt;!&ndash;                      </v-list>&ndash;&gt;
&lt;!&ndash;                    </v-menu>&ndash;&gt;
&lt;!&ndash;                  </v-toolbar>&ndash;&gt;
&lt;!&ndash;                </v-sheet>&ndash;&gt;
&lt;!&ndash;                <v-sheet height="400">&ndash;&gt;
&lt;!&ndash;                  <v-calendar&ndash;&gt;
&lt;!&ndash;                      ref="calendar"&ndash;&gt;
&lt;!&ndash;                      v-model="focus"&ndash;&gt;
&lt;!&ndash;                      :type="ctype"&ndash;&gt;
&lt;!&ndash;                      :events="items"&ndash;&gt;
&lt;!&ndash;                      @click:event="showEvent"&ndash;&gt;
&lt;!&ndash;                      :event-overlap-threshold="30"&ndash;&gt;
&lt;!&ndash;                  ></v-calendar>&ndash;&gt;
&lt;!&ndash;                  <v-menu&ndash;&gt;
&lt;!&ndash;                      v-model="selectedOpen"&ndash;&gt;
&lt;!&ndash;                      :close-on-content-click="false"&ndash;&gt;
&lt;!&ndash;                      :activator="selectedElement"&ndash;&gt;
&lt;!&ndash;                      offset-x&ndash;&gt;
&lt;!&ndash;                  >&ndash;&gt;
&lt;!&ndash;                    <v-card color="grey lighten-4" min-width="350px" flat>&ndash;&gt;
&lt;!&ndash;                      <v-toolbar :color="selectedEvent.color" dark>&ndash;&gt;
&lt;!&ndash;                        <v-toolbar-title&ndash;&gt;
&lt;!&ndash;                            v-html="selectedEvent.name"&ndash;&gt;
&lt;!&ndash;                        ></v-toolbar-title>&ndash;&gt;
&lt;!&ndash;                        <v-spacer></v-spacer>&ndash;&gt;
&lt;!&ndash;                      </v-toolbar>&ndash;&gt;
&lt;!&ndash;                      <v-card-text>&ndash;&gt;
&lt;!&ndash;                        <span v-html="selectedEvent.content"></span>&ndash;&gt;
&lt;!&ndash;                      </v-card-text>&ndash;&gt;
&lt;!&ndash;                      <v-card-actions>&ndash;&gt;
&lt;!&ndash;                        <v-btn&ndash;&gt;
&lt;!&ndash;                            text&ndash;&gt;
&lt;!&ndash;                            color="secondary"&ndash;&gt;
&lt;!&ndash;                            @click="selectedOpen = false"&ndash;&gt;
&lt;!&ndash;                        >&ndash;&gt;
&lt;!&ndash;                          Cancel&ndash;&gt;
&lt;!&ndash;                        </v-btn>&ndash;&gt;
&lt;!&ndash;                      </v-card-actions>&ndash;&gt;
&lt;!&ndash;                    </v-card>&ndash;&gt;
&lt;!&ndash;                  </v-menu>&ndash;&gt;
&lt;!&ndash;                </v-sheet>&ndash;&gt;
&lt;!&ndash;              </div>&ndash;&gt;
              <div class="col-md-12">
                <div class="">
                  <table class="table">
                    <thead class="text-left">
                    <th>Title</th>
                    <th>Date</th>
                    <th v-show="type === 'event'">Event Date</th>
                    <th>Category</th>
                    <th>Action</th>
                    </thead>
                    <tbody class="text-left" v-if="blogs.length > 0">
                    <tr v-for="(blog, index) of blogs" :key="index">
                      <td>
                        <a href="#" @click.prevent="editblog(blog)" class="mr-2"> {{ blog.title }}</a>
                        <i class="fas fa-circle"
                           :class="blog.is_active?'dot-active':'dot-inactive'"></i>
                      </td>
                      <td>
                        {{ blog.pub_date }}
                      </td>
                      <td v-show="type === 'event'">
                        {{ blog.event_date }}
                      </td>
                      <td>
                        <div class v-if="checkLength(blog.category_titles)">
                            <span
                                class="badge badge-secondary m-1"
                                v-for="(title, i) of blog.category_titles"
                                :key="i"
                            >{{ title }}</span
                            >
                        </div>
                        <div class v-else>
                          <span class="badge badge-default">-</span>
                        </div>
                      </td>

                      <td>
                        <b-dropdown
                                size="sm"
                                variant="link"
                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                no-caret
                                right
                                no-flip
                        >
                          <template v-slot:button-content>
                            <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                          </template>
                          &lt;!&ndash;begin::Navigation&ndash;&gt;
                          <div class="navi navi-hover ">


                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('blog', 'edit')">
                              <a href="#" class="navi-link"     @click="editblog(blog)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                <span class="navi-text"> Edit </span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('blog', 'delete')">
                              <a href="#" class="navi-link"   @click="deleteblog(blog.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                <span class="navi-text">  Delete</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                          &lt;!&ndash;end::Navigation&ndash;&gt;
                        </b-dropdown>

                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <b-pagination
                    @input="getBlogs"
                    :event-overlap-mode="mode"
                    :event-overlap-threshold="30"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
                &lt;!&ndash;  &ndash;&gt;
              </div>
            </div>
          </div>
        </div>-->
          </div>
          <create-and-update ref="category"></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./category/CreateAndUpdate";
import BlogCategoryService from "@/core/services/blog/category/BlogCategoryService";

const categoryService = new BlogCategoryService();
import BlogService from "@/core/services/blog/BlogService";

const blogService = new BlogService();
export default {
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      mode: "column",
      focus: "",
      ctype: "month",
      active_statuses: [
        { value: "active", name: "Active" },
        { value: "in_active", name: "Inactive" },
      ],
      types: ["month", "week", "day", "4day"],
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      search: {
        category_id: null,
        type: "",
        active: "active",
      },
      categories: [],

      menu2: false,
      menu: false,
      blogs: [],
      page: null,
      total: null,
      perPage: null,
      loading: false,
    };
  },

  mounted() {
    this.search.type = this.$route.params.type;
    this.getBlogs();
    this.getCategories();
  },
  computed: {
    items() {
      const items = [];
      this.blogs.forEach((item) => {
        if (item.type == "event") {
          items.push({
            name: item.title,
            start: item.event_date,
            end: item.event_date,
            content: item.content,
            color: "blue",
            timed: false,
          });
        }
      });
      return items;
    },
    routeTab() {
      return {
        title: `${this.capitalize(this.type)}`,
        tips: `${this.capitalize(this.type)}`,
      };
    },
    type() {
      let type = this.$route.params.type;
      this.search.type = type;
      return type;
    },
  },
  watch: {
    type(newValue, oldValue) {
      this.getBlogs();
    },
  },
  methods: {
    getBlogs() {
      this.loading = true;
      blogService
        .paginate(this.search, this.page)
        .then((response) => {
          this.blogs = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.loading = false;
        });
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    setToday() {
      this.focus = "";
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getCategories() {
      categoryService
        .paginate(this.type)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    capitalize(string) {
      if (string && string != undefined && string != null) {
        string = string.charAt(0).toUpperCase() + string.slice(1);
        return string;
      }
    },
    editblog(blog) {
      this.$router.push({ name: "blogs-update", params: { id: blog.id } });
    },
    _reset() {
      this.search = {
        type: this.type,
        category_id: "",
        active: null,
      };
      this.getBlogs();
    },
    deleteblog(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            blogService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Information deleted");

                this.getBlogs();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    openDialog(category = null) {
      this.$refs["category"].openDialog(null, this.type);
      // this.$refs["my-modal"].show();
    },
    createNew() {
      this.$router.push({
        name: "blogs-create",
      });
    },
    blogCategory() {
      this.$router.push({
        name: "blog-category-list",
      });
    },
    checkLength(titles) {
      let length = titles.length;
      length = parseInt(length);
      if (length > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>
